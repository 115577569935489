<template>
    <div class="dashboard cust-page-search">
        <div class="cust-page-search__csv">
            <button class="button--oval--csv" type="button" v-on:click="onCsvDownload()">CSVダウンロード</button>
            <button class="button--oval--csv" type="button" @click="fileOpen()">CSVインポート</button>
            <input ref="csvFile" type="file" accept="csv" style="display:none;" @change="selectedFile(arguments[0])">
        </div>
        <div class="cust-page-search__flex">
            <!-- 項目３つ並べたときのデザイン -->
            <div>
                <div>   
                    <h2 class="cust-page-search__input__title">JANコード</h2>
                    <div class="cust-page-search__input">
                        <input v-model="params.search_jancode" type="search">
                    </div>
                </div>
                <div>
                    <h2 class="cust-page-search__input__title">品番</h2>
                    <div class="cust-page-search__input">
                        <input v-model="params.search_itemnumber" type="search">
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <h2 class="cust-page-search__input__title">管理場所</h2>
                    <div class="cust-page-search__input">
                        <div class="flex">
                            <select v-model="params.stock_place" class="stock_place">
                                <option value=""></option>
                                <option value="尾鷲倉庫">尾鷲倉庫</option>
                                <option value="瀬戸倉庫">瀬戸倉庫</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="width">
                    <h2 class="cust-page-search__input__title">商品名</h2>
                    <div class="flex">
                        <div class="cust-page-search__input">
                            <input v-model="params.search_itemname" type="search">
                        </div>
                        <button class="button--oval--primary" type="button" @click="searchPush()">検索</button>
                    </div>
                </div>
            </div>
        </div>
        <table class="cust-table">
            <tr>
                <template v-for="data in tableSet">
                    <th :key="`th${data.key}`" :class="data.class" @click="sort($event, data.key)">
                        {{ data.head }}
                    </th>
                </template>
            </tr>
            <router-link v-for="(list, index) in listStockDetails" :key="`list${index}`" :to="{ name: 'detail', params:{janCode: String(list.jan_code), stockPlaceCode: String(list.stock_place_code)}}" tag="tr">
                <template v-for="data in tableSet">
                    <td v-if="data.key === 'stock_now'" :key="`list${index}${data.key}`" :class="data.class">{{ stockAvailable(list[data.key], list.unit) }}</td>
                    <td v-else-if="data.key === 'item_number' || data.key === 'item_name'" :key="`list${index}${data.key}`" :class="data.class">{{ dataAvailable(list[data.key]) | textEllipsis }}</td>
                    <td v-else :key="`list${index}${data.key}`" :class="data.class">{{ dataAvailable(list[data.key]) }}</td>
                </template>
            </router-link>
        </table>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";
    import inputFile from "@/mixins/inputFile";

    const tableSearch = [
        {
            "class": "",
            "head": "JANコード",
            "key": "jan_code",
        },
        {
            "class": "",
            "head": "品番",
            "key": "item_number",
        },
        {
            "class": "",
            "head": "商品名",
            "key": "item_name",
        },
        {
            "class": "",
            "head": "管理場所",
            "key": "stock_place",
        },
        {
            "class": "",
            "head": "現在庫",
            "key": "stock_now",
        },
        {
            "class": "",
            "head": "最終更新日",
            "key": "final_updated_at",
        },
    ];

    export default {
        "filters": {
            textEllipsis(val) {
                const text = 20 < val.length ? `${val.slice(0, 20)}…` : val;
                return text;
            },
        },
        "mixins": [
            common,
            inputFile,
        ],
        data() {
            return {
                "listItems": [],
                "listStockDetails": [],
                "params": {
                    "search_jancode": "",
                    "search_itemnumber": "",
                    "search_itemname": "",
                    "stock_place": "",
                },
                "tableSet": tableSearch,
            };
        },
        created() {
            this.$root.pagetitle = "品番検索";
            this.fetchingStockData();
        },
        "methods": {
            dataAvailable(val) {
                let data = val ?? "－";
                if (!String(data).match(/\S/g)) {
                    data = "－";
                }
                return data;
            },
            stockAvailable(val, unit) {
                let data = val ?? 0;
                if (!String(data).match(/\S/g)) {
                    data = 0;
                }
                return `${data}${unit}`;
            },
            replaceValue(str) {
                return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
                    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
                });
            },
            sort(event, key) {
                this.listStockDetails = this.$_sort(event, {
                    "data": this.listStockDetails,
                    "key": key,
                    "number": ["stock_now"],
                    "type": "desc",
                });
            },
            createCsvData(data) {
                let csvData = "";
                const head = [
                    "stock_place_code",
                    "stock_place",
                    "jan_code",
                    "item_code",
                    "item_name",
                    "item_number",
                    "item_category",
                    "item_category_name",
                    "stock_first",
                    "stock_now",
                    "unit",
                    "final_updated_at",
                    "net",
                    "wholesale",
                    "list_price",
                    "cost_price",
                    "stock_in",
                    "prepayment",
                    "stock_weight",
                    "spare1",
                    "spare2",
                    "spare3",
                    "spare4",
                ];
                const text = {
                    "stock_place_code": "倉庫区分",
                    "stock_place": "倉庫名称",
                    "jan_code": "JANコード",
                    "item_code": "商品コード",
                    "item_name": "商品名",
                    "item_number": "品番",
                    "item_category": "分類番号",
                    "item_category_name": "分類名",
                    "stock_first": "先在庫",
                    "stock_now": "現在庫",
                    "unit": "単位",
                    "final_updated_at": "最終更新日時",
                    "net": "NET",
                    "wholesale": "卸価格",
                    "list_price": "定価",
                    "cost_price": "原価",
                    "stock_in": "入数",
                    "prepayment": "元払",
                    "stock_weight": "重量",
                    "spare1": "追加１",
                    "spare2": "追加２",
                    "spare3": "追加３",
                    "spare4": "追加４",
                };
                // 行の追加
                const addData = (i) => {
                    for (const key of head) {
                        const val = i === 0 ? text[key] ? text[key] : key : data[i][key] ?? "";
                        csvData += `${val},`;
                    }
                    csvData += "\n";
                };
                // データの追加
                if (data) {
                    // 1行目
                    addData(0);
                    // 2行目以降
                    for (const i in data) {
                        addData(i);
                    }
                }
                return csvData;
            },
            fetchingCsvDownload() {
                return common.$_axios.get(
                    `${common.$_configs.apiUrl}/stock/csv/download`,
                    this.$_requestConfig()
                );
            },
            onCsvDownload() {
                this.fetchingCsvDownload().then(response => {
                    const data = this.createCsvData(response.data);
                    const csv = `\ufeff${data}`;
                    const blob = new Blob([csv], {
                        "type": "text/csv;charset=utf-8",
                    });
                    const link = document.createElement("a");
                    const now = new Date();
                    const Year = now.getFullYear();
                    let Month = now.getMonth()+1;
                    let Day = now.getDate();
                    if (Month < 10) {
                        Month = "0" + Month;
                    }
                    if (Day < 10) {
                        Day = "0" + Day;
                    }
                    const full = Year + Month + Day;
                    const fileName = `全品番在庫データ${full}`;
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${fileName}.csv`;
                    link.click();
                }).catch(() => {
                    this.$_modalError("csvError");
                });
            },
            getParams() {
                for (let key in this.params) {
                    if (this.$route.query[key]) {
                        this.params[key] = this.$route.query[key];
                    }
                }
            },
            selectedFile(e) {
                e.preventDefault();
                let files = e.target.files;
                //画像の場合の処理
                this.$_appendInputFile("csv_file", files[0]);
                this.csvPush();
            },
            searchPush() {
                this.$router.push({ "name": "search", "query": this.params }, () => {}, () => {});
                if (!this.params.search_jancode && !this.params.search_itemnumber && !this.params.search_itemname && !this.params.stock_place) {
                    this.listStockDetails = this.listItems;
                } else {
                    const searchData = [];
                    for (let data of this.listItems) {
                        const jan_code = data.jan_code ? this.replaceValue(data.jan_code.toLowerCase()) : "";
                        // const item_code = data.item_code;
                        const item_number = data.item_number ? this.replaceValue(data.item_number.toLowerCase()) : "";
                        const item_name = data.item_name ? this.replaceValue(data.item_name.toLowerCase()) : "";
                        const stock_place_code = data.stock_place_code;
                        const stock_place = data.stock_place;
                        const stock_now = data.stock_now;
                        const unit = data.unit;
                        const final_updated_at = data.final_updated_at;
                        const pushData = {
                            "jan_code": data.jan_code,
                            "item_number": data.item_number,
                            "item_name": data.item_name,
                            "stock_place_code": stock_place_code,
                            "stock_place": data.stock_place,
                            "stock_now": stock_now,
                            "unit": unit,
                            "final_updated_at": final_updated_at,
                        };
                        if (this.params.stock_place) {
                            if (
                                jan_code.indexOf(this.replaceValue(this.params.search_jancode.toLowerCase())) != -1
                                &&
                                item_number.indexOf(this.replaceValue(this.params.search_itemnumber.toLowerCase())) != -1 
                                &&
                                item_name.indexOf(this.replaceValue(this.params.search_itemname.toLowerCase())) != -1 
                                &&
                                stock_place === this.params.stock_place) {
                                searchData.push(pushData);
                            }
                        } else {
                            if (
                                jan_code.indexOf(this.replaceValue(this.params.search_jancode.toLowerCase())) != -1 
                                && 
                                item_number.indexOf(this.replaceValue(this.params.search_itemnumber.toLowerCase())) != -1 
                                && 
                                item_name.indexOf(this.replaceValue(this.params.search_itemname.toLowerCase())) != -1 ) {
                                searchData.push(pushData);
                            }
                        }
                    }
                    this.listStockDetails = searchData;
                }
            },
            fetchingStockData() {
                // クエリからパラメータを取得
                this.getParams();
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/stock`,
                    this.$_requestConfig()
                ).then(response => {
                    this.listItems = response.data;
                    if (!this.params.search_jancode && !this.params.search_itemnumber && !this.params.search_itemname && !this.params.stock_place) {
                        this.listStockDetails =  response.data;
                    } else {
                        const searchData = [];
                        for (let data of response.data) {
                            const jan_code = data.jan_code ? this.replaceValue(data.jan_code.toLowerCase()) : "";
                            const item_number = data.item_number ? this.replaceValue(data.item_number.toLowerCase()) : "";
                            const item_name = data.item_name ? this.replaceValue(data.item_name.toLowerCase()) : "";
                            const stock_place_code = data.stock_place_code;
                            const stock_place = data.stock_place;
                            const stock_now = data.stock_now;
                            const unit = data.unit;
                            const final_updated_at = data.final_updated_at;
                            const pushData = {
                                "jan_code": data.jan_code,
                                "item_number": data.item_number,
                                "item_name": data.item_name,
                                "stock_place_code": stock_place_code,
                                "stock_place": data.stock_place,
                                "stock_now": stock_now,
                                "unit": unit,
                                "final_updated_at": final_updated_at,
                            };
                            if (this.params.stock_place) {
                                if (
                                    jan_code.indexOf(this.replaceValue(this.params.search_jancode.toLowerCase())) != -1 
                                    &&
                                    item_number.indexOf(this.replaceValue(this.params.search_itemnumber.toLowerCase())) != -1 
                                    &&
                                    item_name.indexOf(this.replaceValue(this.params.search_itemname.toLowerCase())) != -1
                                    &&
                                    stock_place === this.params.stock_place) {
                                    searchData.push(pushData);
                                }
                            } else {
                                if (
                                    jan_code.indexOf(this.replaceValue(this.params.search_jancode.toLowerCase())) != -1  
                                    &&
                                    item_number.indexOf(this.replaceValue(this.params.search_itemnumber.toLowerCase())) != -1 
                                    &&
                                    item_name.indexOf(this.replaceValue(this.params.search_itemname.toLowerCase())) != -1) {
                                    searchData.push(pushData);
                                }
                            }
                        }
                        this.listStockDetails = searchData;
                        this.listStockDetails = this.listStockDetails.sort((a, b) => a.jan_code - b.jan_code);
                    }
                }).catch(() => {
                    this.$_modalError("itemsError");
                });
            },
            fileOpen() {
                this.$refs.csvFile.click();
            },
            csvPush() {
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/stock/csv/import`,
                    this.$_formData(),
                    this.$_requestConfig("file"),
                    this.$_requestConfig()
                ).then(response => {
                    let message = String(response.data.message);
                    if (message) {
                        message = message.replace(/,/g, "\n");
                        alert(message);
                        window.location.reload();
                    } else {
                        message = "在庫データを更新しました";
                        alert(message);
                        window.location.reload();
                    }
                }).catch(() => {
                    this.$_modalError("csvError");
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .flex {
        display: flex;
    }

    .stock_place {
        /* margin: 0 150px 0 0; */
        /* border-radius: 14px; */
        border-radius: 8px;
        border-width: 1px;
        font-size: 21px;
        text-indent: 5%;
        width: 160px;
    }

    .button--oval--primary {
        height: 49px;
        letter-spacing: .4em;
        margin: 8px 0 0 0;
        text-indent: .4em;
        width: 140px;
    }
</style>
