<template>
    <div>
        <input @change="selectedFile(arguments[0])" type="file" :accept="inputFile.accept">
    </div>
</template>

<script>
    "use strict";

    export default {
        data() {
            return {
                "input": {
                    "delete_flag": "0",
                    "file": "",
                },
                "thumbnail": {
                    "image": {
                        "alt": "",
                        "height": "240px",
                        "width": "auto",
                    },
                    "path": "",
                    "pdf": {
                        "alt": "",
                        "height": "240px",
                        "width": "auto",
                    },
                },
            };
        },
        "props": {
            "inputFile": {
                "type": Object,
                "required": true,
            },
        },
        "computed": {
            checkingFilesExtension() {
                let path = this.inputFile.thumbnail.path;
                if (this.input.file) {
                    if (this.input.file.name) {
                        path = this.input.file.name;
                    }
                }
                const extension = path.split(".").slice(-1)[0].toLowerCase();
                if (extension === "pdf") {
                    return true;
                } else {
                    return false;
                }
            },
            "deleteFlag": {
                get() {
                    return this.input.delete_flag;
                },
                set(val) {
                    this.$emit("delete-flag", val);
                    return this.input.delete_flag = val;
                },
            },
        },
        "methods": {
            selectedFile(e) {
                e.preventDefault();
                let files = e.target.files;
                this.input.file = files[0];
                this.$emit("data", this.input.file);
            },
        },
    };
</script>
