"use strict";

import InputFile from "@/components/InputFile.vue";

export default {
    "components": {
        InputFile,
    },
    data() {
        return {
            "formData": new FormData(),
            "inputFile": {
                "accept": ".csv",
            },
        };
    },
    "methods": {
        $_appendInputFile(key, data) {
            let nullFile = new File([""], "", { "type": null });
            this.formData.append(key, data ? data : nullFile);
        },
        $_appendInputData(key, data) {
            this.formData.append(key, data ? data : "");
        },
        $_formData(data) {
            for (let key in data) {
                this.formData.append(key, data[key]);
            }
            return this.formData;
        },
        $_inputFileThumbnail(path) {
            this.inputFile.thumbnail.path = path;
        },
        $_inputFileAccept(accept) {
            this.inputFile.accept = accept;
        },
    },
};
